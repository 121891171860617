import axios from 'axios'

const ReportService = {

  async QuickBooksInit () {
    return axios({
      url: axios.defaults.baseURL + 'report/quickbooks/init',
      method: 'GET'
    })
  },

  async QuickBooksSearch (filters) {
    return axios({
      url: axios.defaults.baseURL + 'report/quickbooks',
      method: 'POST',
      data: JSON.stringify({ filters: filters })
    })
  }
}

export default ReportService
