<style scoped>
.filter-bar .select {
  max-width: 200px !important; /* its like 1996 again wtf */
}
.table-card {
  max-height: none !important;
}
</style>
<template>
  <b-container
    fluid
    class="mb-5"
  >
    <div class="filter-bar p-2 mb-3">
      <b-overlay :show="loading">
        <b-navbar toggleable="xl">
          <b-navbar-toggle target="filter-collapse"></b-navbar-toggle>
          <b-collapse
            id="filter-collapse"
            is-nav
          >
            <b-form-select
              :options="companies"
              text-field="name"
              value-field="id"
              v-model="filters.company_id"
              plain
              class="select company"
            >
              <template #first>
                <b-form-select-option :value="null">Select Company</b-form-select-option>
              </template>
            </b-form-select>

            <b-form-select
              :options="companytypes"
              text-field="type"
              value-field="id"
              v-model="filters.companytype_id"
              plain
              class="select company"
            >
              <template #first>
                <b-form-select-option :value="null">Company Type</b-form-select-option>
              </template>
            </b-form-select>

            <button
              @click="search()"
              class="filter-btn search mx-2"
            >Search</button>

            <button
              @click="resetFilters()"
              class="filter-btn reset"
            >Reset Filters</button>
          </b-collapse>
        </b-navbar>
      </b-overlay>
    </div>

    <b-overlay
      :show="searching"
      v-if="!loading"
    >
      <b-card class="card-border table-card text--black">
        <b-table
          :items="report"
          :fields="fields"
          striped
          small
        >
          <template #cell(qbpath)="data">
            <span :title="data.item.qbpath || 'Not Set!'">{{ (data.item.qbpath !== null) ? data.item.qbpath.split('\\').pop().split('/').pop() : '' }}</span>
          </template>
          <template #cell(id)="data">
            <b-button
              role="button"
              size="sm"
              variant="info"
              :to="'/admin/company/' + data.item.id"
            >
              <b-icon-pencil-fill></b-icon-pencil-fill>
            </b-button>
          </template>
        </b-table>
      </b-card>
    </b-overlay>
  </b-container>
</template>
<script>
import ReportService from '@/services/ReportService'

export default {
  name: 'QuickbooksReport',
  data () {
    return {
      loading: false,
      searching: false,
      companies: [],
      companytypes: [],
      filters: {
        company_id: null,
        companytype_id: null
      },
      report: [],
      fields: [
        { key: 'name', label: 'Company', tdClass: 'align-middle', sortable: true },
        { key: 'type', label: 'Frequency', tdClass: 'align-middle', sortable: true },
        { key: 'qbpath', label: 'QB Company File', tdClass: 'align-middle', sortable: true },
        { key: 'lastsync', label: 'Last Synchronization', tdClass: 'align-middle', sortable: true },
        { key: 'queued', label: 'Queued Tasks', tdClass: 'align-middle', sortable: true },
        { key: 'id', label: '', tdClass: 'align-middle', sortable: false }
      ]
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      this.loading = true
      ReportService.QuickBooksInit().then((response) => {
        this.companies = response.data.info.companies.sort((a, b) => ('' + a.name).localeCompare(b.name))
        this.companytypes = response.data.info.companytypes
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    },

    search () {
      if (this.loading || this.searching) {
        return false
      }
      this.searching = true
      ReportService.QuickBooksSearch(this.filters).then((response) => {
        this.report = response.data.info
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.searching = false
      })
    },

    resetFilters () {
      this.filters = { company_id: null, companytype_id: null }
    }
  }
}
</script>
